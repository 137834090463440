<template>
  <form @submit.prevent="saveCategory">
    <v-text-field
      label="Name"
      v-model="formData.name"
      placeholder="Category name"
    />
    <v-textarea
      name="description"
      v-model="formData.description"
      label="Category description"
      rows="2"
      hint="Write more about your category."
    />
    <v-select
      v-if="!update || (update && category.children.length === 0)"
      clearable
      :items="categories"
      v-model="formData.parent_id"
      item-text="name"
      item-value="id"
      label="Parent Category"
    />

    <p
      class="mt-4 red pa-2 white--text"
      v-if="errorResponse"
    >
      {{ errorResponse }}
    </p>
    <v-btn
      type="submit"
      class="mt-4"
      :color="update ? 'info' : 'primary'"
    >
      {{ update ? 'Update' : 'Create' }}
    </v-btn>
  </form>
</template>

<script>
import { findoutTheErrorMsg } from '@/utils/error-parser.js'

export default {
  name: "CategoryForm",
  data () {
    return {
      formData: {...this.category, parent_id: this.category.parent ? this.category.parent.id : null},
      errorResponse: null
    }
  },
  props: {
    category: {
      type: Object,
      required: false,
      default: () => {}
    },
    update: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    categories () {
      const _cats = this.$store.getters.categories
      if (this.category && this.category.id) {
        return _cats.filter((item) => {
          return item.id !== this.category.id
        })
      } else {
        return _cats
      }
    }
  },
  methods: {
    async saveCategory () {
      const response = await this.$store.dispatch("createOrUpdateCategoryAction", this.formData)
      if (response.status === 200) {
        // TODO: This will be removed in future released. For now it just a monkey patch. SORRY!!
        this.$emit("closeModal")
        window.location.reload()
      }
      if (response && (response.status === 201 || response.status === 200)) {
        this.formData = {}
        this.$ebus.$emit("newToastMessage", {
          message: response.msg,
        });
        this.$emit("closeModal")
      } else {
        this.errorResponse = findoutTheErrorMsg(response.data)
      }
    }
  },
  watch: {
    'category.id' (newValue) {
      if (newValue) {
        this.formData = {...this.category, parent_id: this.category.parent ? this.category.parent.id : null}
      } else {
        this.formData = {}
      }
    }
  }
}
</script>