<template>
  <v-dialog
    v-model="open"
    scrollable
    max-width="760px"
  >
    <v-card>
      <v-card-title>{{ category.name }}</v-card-title>
      <v-divider />
      <v-card-text>
        <key-value-pair-data
          :data="category"
          :date-time-fields="dateTimeFields"
          :ignored-fields="ignoredFieldsToShow"
          :sort="true"
        />
      </v-card-text>

      <v-card-actions
        class="justify-end"
        v-if="!category.is_global"
      >
        <v-btn
          color="info"
          small
          @click="$emit('onEditButtonPress', category)"
        >
          <v-icon small>
            mdi-square-edit-outline
          </v-icon>
          Edit
        </v-btn>
        <v-btn
          class="white--text"
          color="red"
          small
          @click="$emit('onDeleteButtonPress', category)"
        >
          <v-icon small>
            mdi-delete
          </v-icon>
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import KeyValuePairData from '@/components/common/KeyValuePairData.vue'

export default {
  components: { KeyValuePairData },
	name: "CategoryDetailsModal",
	props: {
		category: {
			type: Object,
			required: true
		},
		openModal: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			open: false,
      ignoredFieldsToShow: ["id", "author", "parent", "children"],
      dateTimeFields: ["created_at", "updated_at"],
		}
	},
	watch: {
		openModal (newValue) {
      this.open = newValue
		},
		open (newValue) {
			if (newValue === false) {
				this.$emit("modalClosed")
			}
		}
	}
}
</script>