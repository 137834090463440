<template>
  <div class="category-list">
    <category-details-modal
      :category="selectedCategory"
      :open-modal="detailModal"
      @modalClosed="closeDetailsModal"
      @onEditButtonPress="(_cat) => (selectedCategory = _cat, createUpdateModal=true, isUpdate = true)"
      @onDeleteButtonPress="(_cat) => onDeleteCategory(_cat)"
    />
    <category-form-modal
      :category="selectedCategory"
      :open-modal="createUpdateModal"
      :update="isUpdate"
      @modalClosed="onFormModalClose"
    />
    <my-alert ref="confirm" />
    <v-btn
      dark
      fixed
      bottom
      right
      fab
      class="mb-8 mr-5"
      color="primary"
      @click="createUpdateModal = true"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-list>
      <h1 class="title">
        Categories
      </h1>
      <v-divider />
      <p
        class="text-center pt-5"
        v-if="categories.length === 0"
      >
        No data found.
      </p>
      <div
        v-for="category in categories"
        :key="category.id"
      >
        <v-list-item v-if="category.children.length === 0">
          <v-list-item-avatar>
            <v-icon
              class="primary lighten-1"
              dark
            >
              mdi-shape-outline
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content
            class="category-content"
            @click="openDetailsModal(category)"
          >
            <v-list-item-title>{{ category.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ category.description }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="!category.is_global">
            <v-btn
              small
              icon
              color="info"
              @click.prevent="openEditModal(category)"
            >
              <v-icon>
                mdi-square-edit-outline
              </v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-action v-if="!category.is_global">
            <v-btn
              icon
              small
              class="white--text"
              color="red"
              @click="onDeleteCategory(category)"
            >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-list-group
          v-else
          no-action
          append-icon=""
          disabled
          :value="true"
          :ripple="true"
        >
          <template v-slot:activator>
            <v-list-item-avatar>
              <v-icon
                class="primary lighten-4"
                dark
              >
                mdi-shape-outline
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content @click="openDetailsModal(category)">
              <v-list-item-title>{{ category.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ category.description }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="!category.is_global">
              <v-btn
                small
                icon
                color="info"
                @click.prevent="openEditModal(category)"
              >
                <v-icon>
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action v-if="!category.is_global">
              <v-btn
                icon
                small
                class="white--text"
                color="red"
                @click.prevent="onDeleteCategory(category)"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </template>

          <v-list-item
            v-for="ch_cat in category.children"
            :key="ch_cat.id"
            link
          >
            <v-list-item-avatar>
              <v-icon
                class="primary lighten-1"
                dark
              >
                mdi-shape-outline
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content @click="openDetailsModal(ch_cat)">
              <v-list-item-title>{{ ch_cat.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ ch_cat.description }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="!ch_cat.is_global">
              <v-btn
                small
                icon
                color="info"
                @click.prevent="openEditModal(ch_cat)"
              >
                <v-icon>
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action v-if="!ch_cat.is_global">
              <v-btn
                icon
                small
                class="white--text"
                color="red"
                @click.prevent="onDeleteCategory(ch_cat)"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list-group>
      </div>
    </v-list>
  </div>
</template>


<script>
import MyAlert from '@/components/common/MyAlert.vue'
import CategoryFormModal from './CategoryFormModal.vue'
import CategoryDetailsModal from './CategoryDetailsModal.vue'

export default {
  components: { CategoryFormModal, MyAlert, CategoryDetailsModal },
  name: "CategoryList",
  computed: {
    categories () {
      return JSON.parse(JSON.stringify(this.$store.getters.categories))
    },
    allCategories () {
      return this.$store.getters.flatCategories
    }
  },
  data () {
    return {
      createUpdateModal: false,
      detailModal: false,
      loading: false,
      isUpdate: false,
      selectedCategory: {}
    }
  },
  async created () {
    await this.$store.dispatch('getAllCategoriesFromAPI')
    if (this.$route.query.categoryId) {
      this.selectCategoryFromRouter(this.$route.query.categoryId)
    }
  },
  methods: {
    openDetailsModal (category) {
      this.$router.push({path: this.$route.path, query: {categoryId: category.id} })
    },
    selectCategoryFromRouter (categoryId) {
      const _category = this.allCategories.find((item) => {
        return item.id === categoryId
      })
      if (_category){
        this.selectedCategory = _category
      this.detailModal = true
      } else {
        this.$ebus.$emit("newToastMessage", {
          message: "Category not found.",
          color: "error"
        })
      }
    },
    closeDetailsModal () {
      this.$router.push({path: this.$route.path})
      this.selectedCategory = {}
      this.detailModal = false
    },
   async onFormModalClose () {
      // await this.$store.dispatch('getAllCategoriesFromAPI', null, {root: true})
      this.createUpdateModal = false
      this.selectedCategory = {} 
      this.isUpdate = false
      this.detailModal = false

    },
    openEditModal (category) {
      this.selectedCategory = category
      this.createUpdateModal = true
      this.isUpdate = true
    },
    async onDeleteCategory(category) {
      this.loading = true
      if (
        await this.$refs.confirm.open({
          title: "Delete!!",
          description: "Are you sure about delete?",
        })
      ) {
        const response = await this.$store.dispatch("deleteCategoryAction", category.id);
        if (response && response.status === 206) {
          window.location.reload()
          this.loading = false
          this.detailModal = false
        } else {
          this.loading = false
        }
        this.$ebus.$emit("newToastMessage", {
          message: response.msg,
        });
      } else {
        this.loading = false;
      }
    },
  },
  watch: {
    '$route.query.categoryId' (newValue) {
      if (newValue) {
        this.selectCategoryFromRouter(newValue)
      }
    }
  }
}
</script>


<style scoped>
.category-list {
  margin-top: 24px;
  margin-left: 5px;
  margin-right: 5px;
}
.category-content {
  cursor: pointer;
}
</style>