<template>
  <v-dialog
    v-model="open"
    scrollable
    max-width="760px"
  >
    <v-card>
      <v-card-title>
        {{ update ? `Update "${category.name}"` : 'Create new category' }}
      </v-card-title>
      <v-divider />
      <v-card-text>
        <category-form
          :category="category"
          :update="update"
          @closeModal="open = false"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CategoryForm from './CategoryForm.vue'

export default {
  components: { CategoryForm },
	name: "CategoryFormModal",
	props: {
		category: {
			type: Object,
			required: false,
			default: () => {}
		},
		update: {
			type: Boolean,
			default: false
		},
		openModal: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			open: false,
		}
	},
	watch: {
		openModal (newValue) {
			if (newValue) {
				this.open = true
			}
		},
		open (newValue) {
			if (newValue === false) {
				this.$emit("modalClosed")
			}
		}
	}
}
</script>