<template>
  <v-list class="transparent">
    <div
      v-for="value, key in sortable(data)"
      :key="key"
    >
      <v-list-item v-if="!isIgnored(key)">
        <v-list-item-title>{{ key | titleize }}</v-list-item-title>
        <v-list-item-subtitle class="text-right">
          <span v-if="!isDateTimeField(key) && !isPhotoField(key)">{{ value | trueType | toDefaultValue }}</span>
          <span v-if="isDateTimeField(key)">{{ value | dateToHuman | toDefaultValue }}</span>
          <span v-if="isPhotoField(key)">
            <v-img
              @click="imgZoom = true"
              width="44"
              :src="value | toPhotoUrl | toDefaultValue"
            />
            <v-overlay
              :value="imgZoom"
              @click="closeImageZoomMode"
            >
              <v-img
                @click="keepImageRemainSame"
                contain
                max-width="500"
                :src="value | toPhotoUrl | toDefaultValue"
              />
            </v-overlay>
          </span>
        </v-list-item-subtitle>
      </v-list-item>
    </div>
  </v-list>
</template>

<script>
import { BASE_IMAGE_URL } from "@/utils/constants";

export default {
  name: "KeyValuePairData",
  props: {
    data: {
      type: Object,
      required: true
    },
    ignoredFields: {
      type: Array,
      required: false,
      default: () => []
    },
    dateTimeFields: {
      type: Array,
      required: false,
      default: () => []
    },
    photoFields: {
      type: Array,
      required: false,
      default: () => []
    },
    sort: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      baseImageUrl: BASE_IMAGE_URL,
      imgZoom: false
    }
  },
  methods: {
    isIgnored (fieldName) {
      return this.ignoredFields.includes(fieldName)
    },
    isDateTimeField (fieldName) {
      return this.dateTimeFields.includes(fieldName)
    },
    isPhotoField (fieldName) {
      return this.photoFields.includes(fieldName)
    },
    sortable(data) {
      if (!this.sort) {
        return data
      } else {
        return data
      }
    },
    closeImageZoomMode () {
      this.imgZoom = false
    },
    keepImageRemainSame () {
      setTimeout(() => {
        this.imgZoom = true
      }, 1)
    }
  },
  filters: {
    titleize (value) {
      return value.replace(
        /(\w*\W*|\w*)\s*/g,
        function(txt) {
        return(txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
        }
      ).replace(/_/g," ");
    },
    trueType (value) {
      if (typeof(value) === "boolean") {
        return value ? "Yes" : "No"
      } else if (typeof(value) === "object" && value !== null) {
        if (value.title) {
          return value.title
        }
        if (value.name) {
          // TODO: Will make this better in near future.
          return value.name
        }
      } else {
        return value
      }
    },
    dateToHuman (value) {
      return new Date(value).toDateString()
    },
    toDefaultValue (value) {
      return value ? value : 'N/A' 
    },
    toPhotoUrl (value) {
      if (value) {
        return `${BASE_IMAGE_URL}?path=${value}` 
      }
      return value
    },
  }
}
</script>

<style scoped>
span {
  text-overflow: unset;
  overflow: auto;
  display: inline-block;
  white-space: pre-wrap;
  max-width: 100%;
}
</style>