<template>
  <category-list />
</template>

<script>
import CategoryList from "../../components/money/category/CategoryList.vue"

export default {
  name: "WalletAndCategoryListView",
  components: { CategoryList }
}
</script>