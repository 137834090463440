export function findoutTheErrorMsg(response) {
  if (response) {
    if (response.detail && Array.isArray(response.detail)) {
      return response.detail[0].msg
    }
    if (response.detail && typeof(response.detail) == 'string') {
      return response.detail
    }
    if (response.msg && typeof(response.msg) == 'string') {
      return response.msg
    }
  } else {
    return "An unknown error just occurred. Please check your input once again."
  }
}